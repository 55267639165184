<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="container my-5">

        <div class="row" id="printArea">
          <!-- invoice view page -->
          <div class="col-12">
            <!-- invoice header -->
            <div class="bg-white">
              <img src="/app-assets/images/invoice/orderInvoice/orderInvoiceHeader.png" class="img-fluid" alt="">
            </div>

            <div class="pb-0 container bg-white">
              <div class="row">
                <div class="col-sm-8 col-12 text-center text-sm-left order-2 order-sm-1 pt-2">
                  <h4 class="font-weight-bold" style="color:#F38020;">Invoice: </h4>
                  <table class="table table-borderless mb-0">
                    <tbody>
                    <tr v-if="orderDetails.order_no" class="mb-1">
                      <td class="py-0 pl-0 font-weight-light" style="width: 20%; color: #58595B">Ref :</td>
                      <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">#{{
                          orderDetails.order_no
                        }}</span>
                        <span v-if="orderDetails.appointment">
                          <span><i class="bx bx-link" style="font-size: 1.5rem;"></i></span>
                          <span v-if="orderDetails.appointment.type === 'Home'">
                            <i class="bx bx-home" style="font-size: 1.5rem;"></i>
                          </span>
                            <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i></span>
                            <span class="text-secondary text-light">#{{ orderDetails.appointment.reference }}</span>
                          </span>
                      </td>
                    </tr>
                    <tr v-if="orderDetails.delivery_date" class="mb-1">
                      <td class="py-0 pl-0 font-weight-light" style="width: 20%;color: #58595B">Date :</td>
                      <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">{{
                          orderDetails.delivery_date
                        }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div
                    class="col-sm-4 col-12 text-center text-sm-right order-1 order-sm-2 d-sm-flex justify-content-end mb-1 mb-sm-0">
                </div>
              </div>
              <div class="row py-0">
                <div class="col-12">
                  <hr>
                </div>
              </div>
              <!-- invoice address and contact -->
              <div class="row invoice-info">
                <div class="col-sm-4 col-12">
                  <h6 class="font-weight-bold">Customer Details</h6>
                  <div v-if="orderDetails?.user">
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Name : </span>
                      <span
                          style="color:#404041;font-weight:400;">{{
                          `${orderDetails?.user?.first_name ?? ''} ${orderDetails?.user?.last_name ?? ''}`
                        }}</span>
                    </div>
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Address : </span>
                      <span
                          style="color:#404041;font-weight:400;">{{
                          fullAddress(orderDetails?.user?.customer)
                        }}</span>
                    </div>
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Email : </span>
                      <span style="color:#404041;font-weight:400;">{{ orderDetails?.user?.email }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-12 mt-1">
                  <h6 class="font-weight-bold">Billing Address</h6>
                  <div class="mb-1" v-if="orderDetails?.billingAddress?.id">
                      <span>{{
                          `${orderDetails.billingAddress.street},
                        ${orderDetails.billingAddress.suburb},
                        ${orderDetails.billingAddress.state},
                        ${orderDetails.billingAddress.post_code},
                        ${orderDetails.billingAddress.country}`
                        }}
                      </span>
                  </div>
                </div>

                <div class="col-sm-4 col-12 mt-1">
                  <h6 class="font-weight-bold">Shipping Address</h6>
                  <div class="mb-1" v-if="orderDetails?.shippingAddress?.id">
                      <span>{{
                          `${orderDetails.shippingAddress.street},
                        ${orderDetails.shippingAddress.suburb},
                        ${orderDetails.shippingAddress.state},
                        ${orderDetails.shippingAddress.post_code},
                        ${orderDetails.shippingAddress.country}`
                        }}
                      </span>
                  </div>

                </div>
              </div>
              <div class="row pt-0">
                <div class="col-12">
                  <hr>
                </div>
              </div>
            </div>

            <div class="container bg-white py-0">
              <div class="row">
                <div class="col-12">
                  <h3 class="text-center font-weight-bold" style="color:#F38020" v-if="orderDetails?.delivery_date">
                    Delivery: {{
                      new Date(`${orderDetails.delivery_date}`).toLocaleDateString("en-AU", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    }}</h3>
                </div>
              </div>
            </div>

            <!-- product details table-->
            <div class="container bg-white">
              <div class="row">
                <div class="col-12">
                  <div class="invoice-product-details table-responsive">
                    <table class="table table-borderless mb-0">
                      <thead>
                      <tr class="border-0">
                        <th class="text-left text-white table-text-color-background"
                            style="width: 50%;">
                          PRODUCT
                        </th>
                        <th class="text-center text-white table-text-color-background"
                            style="width: 15%;">QTY
                        </th>
                        <th class="text-center text-white table-text-color-background"
                            style="width: 15%;">UNIT PRICE
                        </th>
                        <th class="text-right text-white table-text-color-background"
                            style="width: 20%;">PRICE
                        </th>
                      </tr>
                      </thead>

                      <tbody v-if="orderDetails.details!==undefined && orderDetails.status === 'Pending'">

                      <tr>
                        <td class="" style="color:#000;font-weight:500;">{{ appointmentDetails.service.name }}</td>
                        <td class="text-center " style="color:#000;font-weight:500;">
                          {{ appointmentDetails.length }}<br>X {{ appointmentDetails.quantity }}
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          ${{ parseFloat(appointmentDetails.unitPrice * 0.01).toFixed(2) }}
                        </td>
                        <td class="text-right" style="color:#000;font-weight:500;">
                          ${{ parseFloat(appointmentDetails.servicePrice * 0.01).toFixed(2) }}
                        </td>
                      </tr>

                      <tr v-for="(cartDetails) in orderDetails.details.cart_data" :key="cartDetails.productId">
                        <td class="text-capitalize" style="color:#000;font-weight:500;">
                          {{ cartDetails.name }} ({{ cartDetails?.product_price?.condition_name }})
                          <div
                              v-if="cartDetails.product_items !== undefined && cartDetails.product_items?.length !== 0">
                            <ul v-for="(singleItem) in cartDetails.product_items" :key="singleItem.id">
                              <li
                                  v-if="singleItem.item_obj.condition === cartDetails.product_price.condition_name">
                                {{ singleItem.item_obj.serial_number }}
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          {{ cartDetails.product_price.quantity }}
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          ${{ parseFloat(cartDetails?.product_price?.amount / 100).toFixed(2) }}
                        </td>
                        <td class="text-right" style="color:#000;font-weight:500;">
                          ${{ parseFloat(cartDetails?.product_price?.sub_total / 100).toFixed(2) }}
                        </td>
                      </tr>

                      </tbody>

                      <tbody v-if="orderDetails.status === 'Processing'">
                      <tr>
                        <td class="" style="color:#000;font-weight:500;">
                          <div class="d-flex">
                            <span>{{ appointmentDetails.service.name }}</span>
                            <span class="ml-auto" style="font-size: 0.8rem">{{
                                appointmentDetails.created_at != null ? new Date(`${appointmentDetails.created_at}`).toLocaleDateString("en-AU", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }) : ''
                              }}</span>
                          </div>
                        </td>
                        <td class="text-center " style="color:#000;font-weight:500;">
                          {{ appointmentDetails.length }}<br>X {{ appointmentDetails.quantity }}
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          ${{ parseFloat(appointmentDetails.unitPrice * 0.01).toFixed(2) }}
                        </td>
                        <td class="text-right" style="color:#000;font-weight:500;">
                          ${{ parseFloat(appointmentDetails.servicePrice * 0.01).toFixed(2) }}
                        </td>
                      </tr>

                      <tr v-for="(product) in customProducts" :key="product.id">

                        <td class="text-capitalize" style="color:#000;font-weight:500;">
                          <div class="d-flex">
                            <span>{{ product.name }}</span>
                            <span class="ml-auto" style="font-size: 0.8rem">{{
                                product.placed_date != null ? new Date(`${product.placed_date}`).toLocaleDateString("en-AU", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }) : ''
                              }}</span>
                          </div>
                          <div v-if="product.productItem !== undefined && product.productItem?.length !== 0">
                            <ul>
                              <div v-for="(singleItem) in product.productItem" :key="singleItem.id">
                                <li>
                                  {{ singleItem.serialNumber }} <span
                                    v-if="singleItem.serialNumber">({{ singleItem.condition }})</span>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">{{
                            product.productItem.length
                          }}
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          ${{ parseFloat((product.productItem[0].unitPrice) / 100).toFixed(2) }}
                        </td>
                        <td class="text-right" style="color:#000;font-weight:500;">
                          ${{ ((product.productItem[0].unitPrice * product.productItem.length) / 100).toFixed(2) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- invoice subtotal -->
                  <div class="pt-0 mx-2">
                    <hr>
                    <table class="table table-borderless ">
                      <tbody>
                      <tr class="mb-1">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">SubTotal :</td>
                        <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(consolidate.subTotal / 100).toFixed(2) }}</span>
                        </td>
                      </tr>

                      <tr class="mb-1" v-for="(charge,index) in consolidateCharges" :key="index">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">
                          <span class="text-capitalize">{{ charge.name }} ({{ charge.sign }}) : </span>
                        </td>
                        <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(charge.amount / 100).toFixed(2) }}
                            </span>
                        </td>
                      </tr>

                      <tr class="mb-1 font-weight-bold">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Grand Total :</td>
                        <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-bold" style="color: #58595B">
                              ${{ parseFloat(consolidate.grandTotal / 100).toFixed(2) }}</span>
                        </td>
                      </tr>

                      <tr class="mb-1">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Paid :</td>
                        <td class="py-0 pl-4 pr-0" style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(consolidate.paid / 100).toFixed(2) }}</span>
                        </td>
                      </tr>

                      <tr class="mb-1" v-if="(consolidate.grandTotal - consolidate.paid)  > 0">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Due :</td>
                        <td class="py-0 pl-4 pr-0" style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat((consolidate.grandTotal - consolidate.paid) / 100).toFixed(2) }}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-end">
                <div class="col-xl-2 col-md-2 col-12 noPrint">
                  <div class="shadow-none">
                    <div class="">
                      <div class="py-2">
                        <button @click="print" class="btn btn-block text-white" style="background:#F38020">
                          <span>Print</span>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-4" v-if="orderNotes.length > 0">
                <div class="col-12">
                  <h5 class="font-weight-bold">Order Notes</h5>
                </div>
              </div>
              <div class="row ">
                <div class="col-12" v-for="(orderNote,index) in orderNotes" :key="index">
                  <p><span class="font-weight-bold">{{ (index + 1) }})</span> {{ orderNote.description }}</p>
                </div>
              </div>
            </div>

            <div class="bg-white">
              <img src="/app-assets/images/invoice/orderInvoice/orderInvoiceFooter.png" class="img-fluid" alt="">
            </div>
          </div>
          <!-- invoice action  -->
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import PrintElement from "@/components/backEnd/mixins/PrintElement";
import AppLayout from "@/layouts/backEnd/AppLayout"
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "ConsolidateInvoice",
  mixins: [ShowToastMessage, Loader, PrintElement],

  components: {
    AppLayout
  },
  data() {
    return {
      getAppointmentParams: {
        with_relation: [
          'order',
          'address',
          'service',
          'appointmentNotes',
          'appointmentCreator',
          'appointmentCharges',
          'customer',
          'customer.address',
          'customer.user',
          'appointmentPayments.payment',
          'technicianAppointment.technician.user',
          'billingAddress'
        ],
      },
      getOrderParams: {
        with_relation: [
          'user',
          'user.customer.address',
          'user.customer.address.business',
          'orderCharges',
          'orderNotes',
          'orderPayments.payment',
          'orderPaymentsByReference',
          'appointment.technicianAppointment.technician.user',
          'shippingAddress.business',
          'billingAddress.business',
          'orderProductItems.productItem.product',
          'orderProductItems.productItem.productItemTransaction',
        ],
      },
      orderPayment: {
        total: '',
        paid: '',
        remaining: '',
        chargesDetails: '',
      },
      subTotal: 0,
      customProducts: [],
      appointmentDetails: {
        id: '',
        reference: '',
        type: '',
        status: '',
        platform: '',
        parking: '',
        quantity: '',
        preference: '',
        date: '',
        time: '',
        length: '',
        service: {
          id: '',
          name: '',
          code: '',
          description: '',
          home_price: '',
          business_price: '',
          commission: '',
          status: '',
        },
        business: null,
        order: null,
        customer: {
          id: 201,
          type: '',
          status: '',
          referral_code: '',
          newsletter_subscription: '',
          user: {
            id: '',
            first_name: '',
            last_name: '',
            email: null,
            phone_number: '',
          },
          address: {
            id: '',
            street: '',
            suburb: '',
            state: '',
            post_code: '',
            country: ''
          }
        },
        address: {
          id: '',
          street: '',
          suburb: '',
          state: '',
          post_code: '',
          country: ''
        },
        technician: {
          firstName: '',
          lastName: ''
        },
        appointmentCharges: [],
        appointmentPayments: [],
        appointmentTotalCharge: 0,
        appointmentTotalSurCharge: 0,
        appointmentTotalServicePrice: 0,
        appointmentTotalDiscount: 0,
        appointmentTotalGst: 0,
        appointmentPaymentStatus: null,
        appointmentTotalPaid: null,
        billingAddress: {
          id: '',
          street: '',
          suburb: '',
          state: '',
          post_code: '',
          country: ''
        },
        appointmentNotes: [],
        appointmentCreator: {
          id: '',
          panel: ''
        },
        appointmentConsent: null,
        unitPrice: '',
        servicePrice: '',
        created_at: '',
      }
    }
  },
  watch: {
    orderDetails(currentValue) {
      currentValue.details = JSON.parse(currentValue.details)
      this.getOrderCalculation(currentValue)
    },
    appointment(currentAppointment) {

      this.appointmentDetails.id = currentAppointment.id ? currentAppointment.id : '';
      this.appointmentDetails.quantity = currentAppointment.quantity ? currentAppointment.quantity : '';
      this.appointmentDetails.unitPrice = currentAppointment.unit_price ? currentAppointment.unit_price : '';
      this.appointmentDetails.service.name = currentAppointment.service && currentAppointment.service.name ? currentAppointment.service.name : '';
      this.appointmentDetails.service.home_price = currentAppointment.service && currentAppointment.service.home_price ? currentAppointment.service.home_price : '';
      this.appointmentDetails.service.business_price = currentAppointment.service && currentAppointment.service.business_price ? currentAppointment.service.business_price : '';
      this.appointmentDetails.type = currentAppointment.type ? currentAppointment.type : '';
      this.appointmentDetails.reference = currentAppointment.reference ? currentAppointment.reference : '';
      this.appointmentDetails.date = currentAppointment.date ? currentAppointment.date : '';
      this.appointmentDetails.parking = currentAppointment.parking ? currentAppointment.parking : '';

      this.appointmentDetails.appointmentNotes = currentAppointment.appointmentNotes ? currentAppointment.appointmentNotes.filter((singleNote) => singleNote.customer_can_view === 'Yes') : [];

      this.appointmentDetails.status = currentAppointment.status ? currentAppointment.status : '';
      this.appointmentDetails.created_at = currentAppointment.created_at ? currentAppointment.created_at : '';

      this.appointmentDetails.customer.user.first_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.first_name ? currentAppointment.customer.user.first_name : '';
      this.appointmentDetails.customer.user.last_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.last_name ? currentAppointment.customer.user.last_name : '';
      this.appointmentDetails.customer.user.email = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.email ? currentAppointment.customer.user.email : '';
      this.appointmentDetails.customer.user.phone_number = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.phone_number ? currentAppointment.customer.user.phone_number : '';

      //customer actual address
      this.appointmentDetails.customer.address.id = (currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address && currentAppointment.customer.address.id) ? currentAppointment.customer.address.id : '';
      this.appointmentDetails.customer.address.street = (currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.street) ? currentAppointment.customer.address.street : '';
      this.appointmentDetails.customer.address.suburb = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.suburb ? currentAppointment.customer.address.suburb : '';
      this.appointmentDetails.customer.address.state = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.state ? currentAppointment.customer.address.state : '';
      this.appointmentDetails.customer.address.post_code = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.state ? currentAppointment.customer.address.state : '';
      this.appointmentDetails.customer.address.country = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.country ? currentAppointment.customer.address.country : '';

      this.appointmentDetails.technician.firstName = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.first_name ? currentAppointment.technicianAppointment.technician.user.first_name : '';
      this.appointmentDetails.technician.lastName = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.last_name ? currentAppointment.technicianAppointment.technician.user.last_name : '';

      // billing address
      this.appointmentDetails.billingAddress.id = currentAppointment.billingAddress && currentAppointment.billingAddress.id ? currentAppointment.billingAddress.id : '';
      this.appointmentDetails.billingAddress.street = currentAppointment.billingAddress && currentAppointment.billingAddress.street ? currentAppointment.billingAddress.street : '';
      this.appointmentDetails.billingAddress.suburb = currentAppointment.billingAddress && currentAppointment.billingAddress.suburb ? currentAppointment.billingAddress.suburb : '';
      this.appointmentDetails.billingAddress.state = currentAppointment.billingAddress && currentAppointment.billingAddress.state ? currentAppointment.billingAddress.state : '';
      this.appointmentDetails.billingAddress.post_code = currentAppointment.billingAddress && currentAppointment.billingAddress.post_code ? currentAppointment.billingAddress.post_code : '';
      this.appointmentDetails.billingAddress.country = currentAppointment.billingAddress && currentAppointment.billingAddress.country ? currentAppointment.billingAddress.country : '';

      // service address
      this.appointmentDetails.address.id = currentAppointment.address && currentAppointment.address.id ? currentAppointment.address.id : '';
      this.appointmentDetails.address.street = currentAppointment.address && currentAppointment.address.street ? currentAppointment.address.street : '';
      this.appointmentDetails.address.suburb = currentAppointment.address && currentAppointment.address.suburb ? currentAppointment.address.suburb : '';
      this.appointmentDetails.address.state = currentAppointment.address && currentAppointment.address.state ? currentAppointment.address.state : '';
      this.appointmentDetails.address.post_code = currentAppointment.address && currentAppointment.address.post_code ? currentAppointment.address.post_code : '';
      this.appointmentDetails.address.country = currentAppointment.address && currentAppointment.address.country ? currentAppointment.address.country : '';

      this.appointmentDetails.appointmentCharges = (currentAppointment.appointmentCharges) ? currentAppointment.appointmentCharges : [];
      this.appointmentDetails.appointmentPayments = (currentAppointment.appointmentPayments) ? currentAppointment.appointmentPayments : [];

      let charge = 0;
      let appointmentTotalServicePrice = 0;
      let appointmentTotalSurCharge = 0;
      let appointmentTotalGst = 0;
      let appointmentTotalDiscount = 0;

      this.appointmentDetails.appointmentCharges.forEach((item) => {

        if (item.type === "GST") {
          charge = charge + item.amount;
          appointmentTotalGst = appointmentTotalGst + item.amount;

        } else if (item.type === "Service Price") {

          charge = charge + item.amount;
          appointmentTotalServicePrice = appointmentTotalServicePrice + item.amount;
          this.appointmentDetails.servicePrice = item.amount;

        } else if (item.type === "Surcharge") {
          charge = charge + item.amount;
          appointmentTotalSurCharge = appointmentTotalSurCharge + item.amount;

        } else if (item.type === "Discount") {
          charge = charge - item.amount;
          appointmentTotalDiscount = appointmentTotalDiscount + item.amount;
        }

      });

      let paidAmount = this.appointmentDetails.appointmentPayments.reduce((init, item) => {
        return (init + item.payment.total_transaction);
      }, 0);

      if (charge >= paidAmount) {
        this.appointmentDetails.appointmentPaymentStatus = "Paid";
      } else if (paidAmount === 0) {
        this.appointmentDetails.appointmentPaymentStatus = "Unpaid";
      } else {
        this.appointmentDetails.appointmentPaymentStatus = "Partially paid";
      }

      this.appointmentDetails.appointmentTotalCharge = charge;
      this.appointmentDetails.appointmentTotalSurCharge = appointmentTotalSurCharge;
      this.appointmentDetails.appointmentTotalGst = appointmentTotalGst;
      this.appointmentDetails.appointmentTotalDiscount = appointmentTotalDiscount;
      this.appointmentDetails.appointmentTotalServicePrice = appointmentTotalServicePrice;
      this.appointmentDetails.appointmentTotalPaid = paidAmount;
      this.appointmentDetails.length = currentAppointment.length ? (currentAppointment.length === "00:00:00" ?
          (`${this.settingAppointmentServiceDuration.value.split(':')?.[0] ?? 0} hr ${this.settingAppointmentServiceDuration.value.split(':')?.[1] ?? 0} min`) :
          `${currentAppointment.length.split(':')?.[0] ?? 0} hr  ${currentAppointment.length.split(':')?.[1] ?? 0} min`) : "";


    },
  },
  computed: {
    ...mapGetters({

      orderDetails: "appOrders/order",
      appointment: "appAppointments/appointment",
      settingAppointmentServiceDuration: 'appSettings/settingAppointmentServiceDuration',
    }),
    consolidate() {
      return {
        subTotal: this.appointmentDetails.servicePrice + this.subTotal,
        surcharge: this.appointmentDetails.appointmentTotalSurCharge + this.orderPayment?.chargesDetails[1]?.amount,
        discount: this.appointmentDetails.appointmentTotalDiscount + this.orderPayment?.chargesDetails[2]?.amount,
        gst: this.appointmentDetails.appointmentTotalGst + this.orderPayment?.chargesDetails[3]?.amount,
        deliveryCharge: this.orderPayment?.chargesDetails[4]?.amount,
        grandTotal: this.appointmentDetails.appointmentTotalCharge + this.orderPayment.total,
        paid: this.appointmentDetails.appointmentTotalPaid + this.orderPayment.paid,
      }
    },
    consolidateCharges() {
      return [
        {
          name: 'surcharge',
          sign: '+',
          amount: this.appointmentDetails.appointmentTotalSurCharge + this.orderPayment?.chargesDetails[1]?.amount
        },
        {
          name: 'discount',
          sign: '-',
          amount: this.appointmentDetails.appointmentTotalDiscount + this.orderPayment?.chargesDetails[2]?.amount
        },
        {
          name: 'GST',
          sign: '+',
          amount: this.appointmentDetails.appointmentTotalGst + this.orderPayment?.chargesDetails[3]?.amount
        },
        {name: 'delivery charge', sign: '+', amount: this.orderPayment?.chargesDetails[4]?.amount},
      ]
    },

    orderNotes() {
      return (this.orderDetails?.orderNotes ?? []).filter((singleNote) => singleNote.customer_can_view === 'Yes')
    },

    charges() {
      return {
        gst_charge: {
          name: 'GST Charge',
          sign: '+'
        },
        order_delivery_charge: {
          name: 'Delivery Charge',
          sign: '+'
        },
        applied_discount: {
          name: 'Applied Discount',
          sign: '-'
        },
        coupon_discount: {
          name: 'Coupon Discount',
          sign: '-'
        },
        credited_payment_discount: {
          name: 'Credited Discount',
          sign: '-'
        },

      };
    }

  },
  methods: {
    ...mapActions({

      getSettings: "appSettings/getSettings",
      getOrder: 'appOrders/getOrder',
      postOrderInvoice: 'appOrders/postOrderInvoice',
      postAppointmentInvoice: "appAppointments/postAppointmentInvoice",
    }),
    fullAddress(customerObj) {
      let businessName = customerObj?.address?.business?.name ?? '';
      let addressStreet = customerObj?.address?.street;
      let addressSuburb = customerObj?.address?.suburb;
      let addressState = customerObj?.address?.state.toUpperCase();
      let addressPostCode = customerObj?.address?.post_code;
      let addressCountry = customerObj?.address?.country;
      return customerObj?.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    async postSingleOrderInvoice(uuid) {
      let paramObj = {
        uuid: uuid,
        params: this.getOrderParams,
      };
      await this.postOrderInvoice(paramObj).then(async (response) => {
        this.loader(false);
        if (response && response.status === 404) {
          await this.$router.push({name: '404'});
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    print() {
      let idElement = document.getElementById('printArea');
      this.printElement(idElement);
    },

    async getOrderCalculation(order) {
      let orderCharge = await this.calculateOrderCharges(order);
      let orderPayment = await this.calculateOrderPayments(order);
      let orderChargesDetails = await this.getOrderChargesDetails(order);
      await this.generateProcessingProduct(order);

      let remaining = orderCharge - orderPayment;
      this.orderPayment = {
        total: orderCharge,
        paid: orderPayment,
        remaining: remaining,
        chargesDetails: orderChargesDetails
      };
      this.subTotal = order.status === 'Pending' ? order.details.cart_data.reduce(function (acc, cur) {
        return acc + cur.product_price.sub_total
      }, 0) : orderChargesDetails[0].amount;

    },
    async generateProcessingProduct(singleOrder) {
      if (singleOrder.status === 'Processing') {
        const productsSet = {};
        // Loop through each product item in the order
        this.orderDetails.orderProductItems.forEach(item => {
          const {id, name} = item.productItem.product;
          const productItemDetails = {
            condition: item.productItem.condition,
            serialNumber: item.productItem.serial_number,
            unitPrice: item.productItem.productItemTransaction?.sell_price
          };

          // If the product ID exists in the set, add the product details
          // Otherwise, create a new entry for the product ID
          if (!productsSet[id]) {
            productsSet[id] = {
              id: id,
              name: name,
              placed_date: item.placed_date,
              productItem: []
            };
          }
          productsSet[id].productItem.push(productItemDetails);
        });

        // convert product items to array
        const productData = [...Object.values(productsSet)];
        // Assign the organized set of product items to customProducts after shorting by placed date
        this.customProducts = productData.sort((a, b) => (new Date(a.placed_date) - new Date(b.placed_date)));
      }
    },

    async calculateOrderCharges(singleOrder) {
      const decreaseType = ['Discount', 'Coupon']
      if (singleOrder.status === 'Pending') {
        return singleOrder?.details?.grand_total ?? 0;
      } else {
        return singleOrder.orderCharges.reduce(function (accumulate, cur) {
          return (decreaseType.includes(cur.type)) ? (accumulate - cur.amount) : (accumulate + cur.amount);
        }, 0);
      }

    },

    async calculateOrderPayments(singleOrder) {
      if (singleOrder.status === 'Pending') {
        return singleOrder.orderPaymentsByReference.reduce(function (accumulate, current) {
          return accumulate + current.total_transaction
        }, 0);
      } else {
        return singleOrder.orderPayments.reduce(function (accumulate, current) {
          return (!('payment' in current) && (!current.payment)) ? 0 : (accumulate + current.payment.total_transaction)
        }, 0);
      }

    },

    async getOrderChargesDetails(singleOrder) {
      /*const chargesArray = [
        'gst_charge',
        'order_delivery_charge',
        'applied_discount',
        'coupon_discount',
        'credited_payment_discount',
      ];

      return chargesArray.filter((chargeName) =>
          chargeName in singleOrder.details && singleOrder.details[chargeName].applied_status === true).map((chargeName) => {
        return {name: chargeName, amount: singleOrder.details[chargeName].amount}
      });*/
      let orderItemsPrice = 0, orderGST = 0, orderDiscount = 0, orderSurCharge = 0, orderDeliveryCharge = 0,
          appliedDiscount = 0, couponDiscount = 0, creditedPaymentDiscount = 0,
          discountDetails = [];


      if (singleOrder.status === 'Pending') {
        orderItemsPrice = singleOrder.details['subtotal_amount'] ?? 0;
        orderSurCharge = singleOrder.details['surcharge'] ?? 0
        orderGST = singleOrder.details['gst_charge'].applied_status === true ? singleOrder.details['gst_charge'].amount : 0;
        orderDeliveryCharge = singleOrder.details['order_delivery_charge'].applied_status === true ? singleOrder.details['order_delivery_charge'].amount : 0;

        appliedDiscount = singleOrder.details['applied_discount'].applied_status === true ? singleOrder.details['applied_discount'].amount : 0;
        couponDiscount = singleOrder.details['coupon_discount'].applied_status === true ? singleOrder.details['coupon_discount'].amount : 0;
        creditedPaymentDiscount = singleOrder.details['credited_payment_discount'].applied_status === true ? singleOrder.details['credited_payment_discount'].amount : 0;
        orderDiscount = appliedDiscount + couponDiscount + creditedPaymentDiscount;
        discountDetails = [
          {name: 'applied discount', amount: appliedDiscount},
          {name: 'coupon discount', amount: couponDiscount},
          {name: 'credited payment discount', amount: creditedPaymentDiscount},
        ]
      } else {
        singleOrder.orderCharges.forEach((charge) => {
          if (charge.type === 'GST') {
            orderGST = orderGST + charge?.amount ?? 0
          }
          if (charge.type === 'Surcharge') {
            orderSurCharge = orderSurCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Items Price') {
            orderItemsPrice = orderItemsPrice + charge?.amount ?? 0
          }
          if (charge.type === 'Delivery Charge') {
            orderDeliveryCharge = orderDeliveryCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Discount') {
            orderDiscount = orderDiscount + charge?.amount ?? 0
            discountDetails.unshift({name: charge.name, amount: charge.amount})
          }
        });
      }

      return [
        {name: 'items price', sign: '+', amount: orderItemsPrice},
        {name: 'surcharge', sign: '+', amount: orderSurCharge},
        {
          name: 'discount', sign: '-', amount: orderDiscount,
          details: discountDetails
        },
        {name: 'GST', sign: '+', amount: orderGST},
        {name: 'delivery charge', sign: '+', amount: orderDeliveryCharge},
      ]
    },
    async getSingleAppointment(id) {
      let paramObj = {
        uuid: id,
        params: this.getAppointmentParams,
      };
      await this.postAppointmentInvoice(paramObj);
    },
  },
  async mounted() {

    this.loader(true);
    await this.getSettings({
      type: ['appointment'],
      key: ['appointment_service_duration']
    });
    const routeParams = await this.$route.params;
    const [appUuid, ordUuid] = routeParams.uuid.toString().split('&');
    await this.getSingleAppointment(appUuid);
    await this.postSingleOrderInvoice(ordUuid);
    this.loader(false);
  },

}
</script>

<style scoped>
.table-text-color-background {
  border-right: 1px solid white;
  background: #F38020 !important;
}

@media print {
  .table-text-color-background {
    border-right: 1px solid white;
    background: #F38020 !important;
  }

}

</style>
